import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import "../styles.css";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import data from "../../src/data/processToDevelop.json";


gsap.registerPlugin(ScrollTrigger);

export default function ProcessToDevelop() {
  useEffect(() => {
    // Obtén todas las secciones con la clase "panel"
    const sections = gsap.utils.toArray(".panel");

    // Animación de desplazamiento
    const scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".container-scroll-gsap",
        pin: true,
        scrub: 0.1,
        end: "+=3000"
      }
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <>
      <div id="TUIDEA"></div>
      <div className="bg-gradient-to-r from-blue-incubator-navbar to-blue-600 body-font block lg:hidden">

        {/* Carrusel responsive */}
        <div className="container px-5 py-24 mx-auto" >
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            transitionDuration={1}
            customTransition="transform 2800ms"
            showDots={false}
            arrows={true}
          >
            {data.map((data, index) => {
              if (data.active === true) {
                return (

                  <div key={index} className="flex flex-wrap px-4">
                    <div className="sm:w-1/2 mb-10 px-4 flex items-center">
                      <div >
                        <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3 w-full sm:w-full"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        />
                        <p className="leading-relaxed text-sm text-justify  text-white"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                        <div className="flex md:justify-start  justify-center">
                          <a href="#CONTACTANOS"
                            className="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-1 px-4 focus:outline-none hover:bg-indigo-600 text-sm font-semibold">
                            Más información
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="sm:w-1/2 mb-10 px-4">
                      <div className="container">
                        <div className="flex flex-col">
                          {data.items.map((item, index) => {
                            if (item.active === true) {
                              return (
                                <div key={index} className="p-2 lg:w-1/1 md:w-1/1 w-full">
                                  <div className="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                                    <p className="text-white text-justify text-sm"
                                      dangerouslySetInnerHTML={{ __html: item.description }}
                                    />
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>

                    </div>

                  </div>
                );
              }
              return null;
            })}

          </Carousel>
        </div>

      </div>
      <div className="hidden lg:block">
        {/* Carrusel pc */}
        <div className="container-scroll-gsap bg-gradient-to-r from-blue-incubator-navbar to-blue-600 body-font block">
          {data.map((data, index) => {
            if (data.active === true) {
              return (
                <div key={index} className="panel">
                  <div className="container">
                    <div className="flex flex-wrap">
                      <div className="sm:w-1/2 lg:w-1/2 px-4 flex items-center">
                        <div>
                          <h2
                            className="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[60%] sm:w-full"
                            dangerouslySetInnerHTML={{ __html: data.title }}
                          />
                          <p className="leading-relaxed text-justify  text-white"
                            dangerouslySetInnerHTML={{ __html: data.description }}
                          />
                          <div className="flex md:justify-start  justify-center">
                            <a href="#CONTACTANOS"
                              className="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-[#6c3880] transition-colors text-2xl font-semibold">
                              Más información
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="sm:w-1/2 px-4">
                        <div className="container">
                          <div className="flex flex-col">
                            {data.items.map((item, index) => {
                              if (item.active === true) {
                                return (
                                  <div key={index} className="p-2 lg:w-5/6 md:w-1/1 w-full">
                                    <div className="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                                      <p className="text-white text-justify"
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
}
