
import React from 'react'
import { StrictMode } from "react";
import Header from "../header/header";
import WhyTicongleIncubator from "../why-ticongle-incubator/whyTicongleIncubator";
import Alliance from "../alliance-with/alliance";
import ContactUs from "../contact-us/contactUs";
import Footer from "../footer/footer";
import ProcessToDevelop from "../process-to-develop/processTodevelop";

import ScrollUp from "../footer/scroll-up/scroll-up";
import Navbar from '../navbar/navbar';


function Home() {
    return (
        <> 
            <Navbar />
            <Header />
            <WhyTicongleIncubator />
            <ProcessToDevelop />
            <Alliance />
            <ContactUs />
            <Footer></Footer>
            <ScrollUp />
        </>
    )
}

export default Home