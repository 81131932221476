import React from 'react'

function ContactUs() {
    return (

        <div className="container-fluid body-fon bg-blue-sky-incubator">
            <div className="flex flex-wrap ">
                <div className="flex flex-wrap justify-center flex-col sm:w-3/5 md:px-14 px-4 h-full height-ticongle-incubator lg:py-48 py-24" >

                    <h1 className="p-2 text-white lg:text-3xl text-2xl font-bold md:w-[55%] w-full lg:text-left text-center" data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">¿Para quién es TICONGLE INCUBATOR?</h1>
                    <p className="p-2 text-white text-lg text-justify" data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">
                        Para todas las personas y equipos que tienen una idea de negocio, pero necesitan el apoyo de profesionales para desarrollar sus ideas.
                    </p>
                    <div className="flex md:justify-start  justify-center">
                        <button className="
                            mt-4 text-white
                            bg-yellow-incubaodr border-0 rounded-3xl  
                            py-2 px-8 focus:outline-none 
                            hover:bg-[#C87100]
                            transition-colors
                            font-semibold
                            lg:text-2xl text-sm
                            "
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="1500"
                            onClick={() => window.open('https://wa.me/+50376029613', '_blank', 'noopener,noreferrer')}
                        >CUÉNTANOS TU IDEA</button>
                    </div>
                </div>
                <div className="sm:w-2/5 bgCoheteContactUs bg-bgImageContactUs"></div>
            </div>
        </div>
    )
}

export default ContactUs