import gsap from "gsap";
import React, { useEffect, useRef } from "react"

const SvgComponentHumo = (props) => {

    const complementTheCohete = useRef(null);
    
    useEffect(() => {
        console.log("complementTheCohete ==>", complementTheCohete);

        const complementCohete = complementTheCohete.current;

        gsap.to(complementCohete, {
            duration:2.6,
            repeat:-1,
            ease: "back.inOut(1.7)",
            y: 20
          });
    }, []);

    return(
        <svg
        ref={complementTheCohete}
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
          enableBackground: "new 0 0 1920 1080",
        }}
        viewBox="0 0 1920 1080"
        {...props}
      >
        <style>{".st2{display:inline}.st3{fill:#f08299}.st6{fill:#ffe02e}"}</style>
        <g id="Capa_2">
          <linearGradient
            id="SVGID_1_"
            x1={482.388}
            x2={482.388}
            y1={722.889}
            y2={1055.15}
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0.273}
              style={{
                stopColor: "#fff",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#fff",
              }}
            />
          </linearGradient>
          <path
            d="M917.6 1080.76c29.66-49.78 48.37-112.73 47.11-170.64-1.1-50.7-15.73-102.69-49.53-140.5-9.21-10.3-19.78-19.52-27.37-31.07s-13.28-26.2-8.31-39.09h-25.28c2.85 12.03-.82 24.87-7.32 35.39s-15.63 19.11-24.69 27.53c-13.16 12.21-26.56 24.47-36.55 39.39-10 14.92-16.41 33.02-13.91 50.8-16.47-4.15-35.93-.08-45.76 13.77-15.16-14.01-38.62-22.37-59.22-21.11-20.6 1.26-40.53 11.23-53.88 26.98-14.16-6.94-33.34-7.28-47.53-.41-14.19 6.88-24.91 20.52-28.23 35.94-8.34-11.95-22.01-20.44-36.48-22.18-14.47-1.74-29.59 2.97-40.52 12.61-13.21-16.8-32.91-30.21-54.12-32.79-21.21-2.57-43.35 3.58-60.19 16.73-2.09-17.02-10.95-32.48-24.72-42.69-13.78-10.21-31.81-14.48-48.7-11.53-4.73-22.03-20.5-43.67-39.73-55.41-19.24-11.73-43.34-15.18-65.09-9.32-3-39.89-22.72-79.1-54.36-103.57C81.56 635.14 39.36 624.83 0 631.96v448.8h917.6z"
            style={{
              fill: "#fff",
            }}
          />
          <linearGradient
            id="SVGID_00000034774390408476939500000018264984246732062608_"
            x1={6927.278}
            x2={6927.278}
            y1={727.053}
            y2={1059.315}
            gradientTransform="matrix(-1 0 0 1 8364.89 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0.273}
              style={{
                stopColor: "#fff",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#fff",
              }}
            />
          </linearGradient>
          <path
            d="M1920 1080.76V636.13c-39.36-7.13-81.56 3.17-113.21 27.64-31.65 24.47-51.36 63.68-54.36 103.57-21.75-5.87-45.86-2.42-65.09 9.32-19.24 11.74-35 33.38-39.73 55.41-16.89-2.95-34.92 1.32-48.7 11.53-13.78 10.21-22.63 25.67-24.72 42.69-16.84-13.15-38.98-19.3-60.19-16.73-21.21 2.57-40.91 15.99-54.12 32.79-10.93-9.64-26.05-14.35-40.52-12.61-14.47 1.74-28.14 10.22-36.48 22.18-3.32-15.41-14.04-29.06-28.23-35.94-14.19-6.88-33.37-6.53-47.53.41-13.35-15.74-33.28-25.72-53.88-26.98-20.6-1.26-44.06 7.11-59.22 21.11-9.83-13.84-29.3-17.91-45.76-13.77 2.5-17.78-3.92-35.89-13.91-50.8-9.99-14.92-23.39-27.18-36.55-39.39-9.06-8.41-18.19-17.01-24.69-27.53s-10.17-23.36-7.32-35.39h-25.28c4.97 12.89-.72 27.54-8.31 39.09s-18.16 20.77-27.37 31.07c-33.8 37.81-48.43 89.8-49.53 140.5-1.22 56.31 16.43 117.38 44.67 166.48H1920z"
            style={{
              fill: "#fff",
            }}
          />
          <circle cx={1386.44} cy={984.52} r={6.9} className="st6" />
          <circle cx={1134.13} cy={908.32} r={3.88} className="st6" />
          <path
            d="M1189.42 961.77a5.45 5.45 0 1 1-10.9 0 5.45 5.45 0 0 1 10.9 0z"
            className="st6"
          />
          <circle cx={1107.75} cy={869.93} r={2.95} className="st6" />
          <path
            d="M1417.76 900.71c0 .91-.74 1.65-1.65 1.65-.91 0-1.65-.74-1.65-1.65 0-.91.74-1.65 1.65-1.65.92 0 1.65.74 1.65 1.65z"
            className="st6"
          />
          <circle cx={1121.08} cy={879.27} r={1.92} className="st6" />
          <path
            d="M1193.59 887.87c0 2.66-2.15 4.81-4.81 4.81s-4.81-2.15-4.81-4.81c0-2.66 2.15-4.81 4.81-4.81s4.81 2.16 4.81 4.81zM1414.47 978.28c0 2.25-1.83 4.08-4.08 4.08s-4.08-1.83-4.08-4.08 1.83-4.08 4.08-4.08 4.08 1.83 4.08 4.08z"
            className="st6"
          />
          <circle cx={533.56} cy={984.52} r={6.9} className="st6" />
          <circle cx={785.87} cy={908.32} r={3.88} className="st6" />
          <path
            d="M730.58 961.77a5.45 5.45 0 1 0 10.9 0 5.45 5.45 0 0 0-10.9 0z"
            className="st6"
          />
          <circle cx={812.25} cy={869.93} r={2.95} className="st6" />
          <path
            d="M502.24 900.71c0 .91.74 1.65 1.65 1.65.91 0 1.65-.74 1.65-1.65 0-.91-.74-1.65-1.65-1.65-.91 0-1.65.74-1.65 1.65z"
            className="st6"
          />
          <circle cx={798.92} cy={879.27} r={1.92} className="st6" />
          <path
            d="M726.41 887.87c0 2.66 2.15 4.81 4.81 4.81 2.66 0 4.81-2.15 4.81-4.81 0-2.66-2.15-4.81-4.81-4.81-2.66 0-4.81 2.16-4.81 4.81zM505.53 978.28c0 2.25 1.83 4.08 4.08 4.08s4.08-1.83 4.08-4.08-1.83-4.08-4.08-4.08-4.08 1.83-4.08 4.08z"
            className="st6"
          />
          <linearGradient
            id="SVGID_00000014615949287915119850000015088473167988981651_"
            x1={7404.89}
            x2={7404.89}
            y1={956.661}
            y2={1234.958}
            gradientTransform="matrix(-1 0 0 1 8364.89 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset={0}
              style={{
                stopColor: "#fff",
              }}
            />
            <stop
              offset={1}
              style={{
                stopColor: "#fff",
              }}
            />
          </linearGradient>
          <path
            d="M1920 1080.76V880.25c-15.91-9.39-36.71-9.87-53.04-1.23-16.33 8.64-28.86 25.63-30.04 44.07-33.15-13.78-71.66-14.14-105.07-.99s-63.78 43.01-78.65 75.69c-20.35-5.48-44.52 7.65-51.37 27.58-20.61-17.37-50.11-23.56-75.97-15.95-25.86 7.61-48.31 31.8-56.23 57.56-4.3-6.06-11.52-9.95-18.95-10.19-7.43-.25-14.48 1.18-19.17 6.94-20.31-31.6-53.51-54.64-90.22-62.62-36.71-7.98-75.95 6.3-107.55 26.62-5.55-15.05-29.89-18.65-42.06 1.9-4.14-14.58-14.3-27.36-27.57-34.68-13.27-7.33-28.84-9.77-43.39-5.51 3.16-15.37-2.88-31.37-12.03-44.12-9.15-12.75-21.24-23.02-32.26-34.19-28.82-29.21-59.89-72.17-72.16-110.82-16.2-51.03-21.14-104.31-25.54-157.59h-37.46c-4.4 53.29-9.34 106.56-25.54 157.59-12.27 38.65-43.34 81.61-72.16 110.82-11.02 11.17-23.11 21.44-32.26 34.19s-15.19 28.75-12.03 44.12c-14.55-4.26-30.12-1.82-43.39 5.51s-23.42 20.1-27.57 34.68c-12.16-20.55-36.5-16.95-42.06-1.9-31.6-20.32-70.84-34.6-107.55-26.62-36.71 7.98-69.91 31.02-90.22 62.62-4.69-5.77-11.74-7.19-19.17-6.94-7.43.25-14.65 4.13-18.95 10.19-7.93-25.76-30.38-49.96-56.23-57.56-25.86-7.61-55.35-1.41-75.97 15.95-6.85-19.93-31.02-33.06-51.37-27.58-14.86-32.68-45.24-62.54-78.65-75.69-33.41-13.15-71.92-12.79-105.07.99-1.18-18.44-13.71-35.42-30.04-44.07-16.33-8.64-37.13-8.16-53.04 1.23v200.52h1920z"
            style={{
              fill: "#fff",
            }}
          />
        </g>
      </svg>
    )

}
export default SvgComponentHumo;
