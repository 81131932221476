import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function WhatsappBtn () {
    return (
        <button
            className="bg-[#25D366] border-none rounded-full w-11 h-11 flex items-center justify-center text-white text-2xl shadow-md transition-colors ease-in-out duration-300 fixed bottom-4 right-8 z-50 hover:bg-[#1ebd56]"
            onClick={() => window.open('https://wa.me/+50376029613', '_blank', 'noopener,noreferrer')}
        >
            <FontAwesomeIcon icon={faWhatsapp} />
        </button>
    );
}

export default WhatsappBtn; 