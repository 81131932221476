
import { BrowserRouter } from "react-router-dom";
import RouterApp from "./route/routes";



function App() {
    return (
        <div>
            <BrowserRouter>
                <RouterApp></RouterApp>

            </BrowserRouter>
        </div>
    )
}

export default App