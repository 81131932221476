import React from 'react'

import ayudanos from "../assets/icons/ayudanos.png";
import habilidad from "../assets/icons/habilidad.png";
import date from "../assets/icons/date.png";
import data from "../../";

function WhyTicongleIncubator() {
  return (
    <div className="body-fon py-16" id="SOMOS">
      <div className="container px-5 pb-24 pt-4 mx-auto">

        <div className="flex flex-col">
          <div className="text-center">
            <h1 className="text-gray-incubator text-3xl text-center font-bold"
              data-aos="fade-up"
              data-aos-duration="3000"
            > ¿Porqué <span> TICONGLE INCUBATOR</span>? </h1>

            <div className="flex mt-2 justify-center">
              <div className="w-[50%] h-2 bg-yellow-incubaodr inline-flex" data-aos="fade-up" data-aos-duration="3000"></div>
            </div>
          </div>

          <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-4">
            <p className="leading-relaxed text-base text-justify  font-normal pl-0" data-aos="fade-up" data-aos-duration="3000"
            >
              Para nosotros, cualquier idea de negocio es buena y puede ser desarrollada. Para nosotros es fundamental hacer realidad tu sueño de emprender y tener tu propio negocio. Sabemos que empezar con tus ideas no es fácil; es por eso que ponemos a tu disposición un equipo de profesionales con experiencia a la hora de desarrollar ideas.
            </p>
          </div>

        </div>


        <div className="container mx-auto">

          <div className="flex flex-wrap -m-4 ">

            <div className="p-4 md:w-1/3 sm:w-1/1 w-full">
              <div className="border-2 border-gray-200 px-9 py-6 rounded-3xl bg-blue-sky-incubator h-full" data-aos="fade-up" data-aos-duration="3000">
                <div className="flex justify-center">
                  <img src={ayudanos} alt="" className="icons-why-incubator pb-4" />
                </div>
                <h2 className="title-font font-bold text-lg pb-2 text-white text-center">Ayudamos a que tu negocio empiece y crezca</h2>
                <p className="leading-relaxed text-white text-base text-justify">No importa si necesitas ayuda escogiendo la idea correcta, despegando tu proyecto o haciendo crecer ese emprendimiento que ya has tenido por años; nosotros te ayudamos.
                </p>
              </div>
            </div>

            <div className="p-4 md:w-1/3 sm:w-1/1 w-full">
              <div className="border-2 border-gray-200 px-9 py-6 rounded-3xl bg-blue-sky-incubator h-full" data-aos="fade-up" data-aos-duration="3000"

              >
                <div className="flex justify-center">
                  <img src={habilidad} alt="" className="icons-why-incubator pb-4" />
                </div>
                <h2 className="title-font font-bold text-lg pb-9 text-white text-center">Aprende las habilidades necesarias</h2>
                <p className="leading-relaxed text-white text-base text-justify">Cualquiera puede arrancar un negocio con la formación y ayuda adecuada. Nuestro trabajo es proveer inspiración, información y ayuda práctica para llevar tu idea al siguiente nivel.
                </p>
              </div>
            </div>

            <div className="p-4 md:w-1/3 sm:w-1/1 w-full">
              <div className="border-2 border-gray-200 px-9 py-6 rounded-3xl bg-blue-sky-incubator h-full" data-aos="fade-up" data-aos-duration="3000" >
                <div className="flex justify-center">
                  <img src={date} alt="" className="icons-why-incubator pb-4" />
                </div>
                <h2 className="title-font font-bold text-lg pb-2 text-white text-center">Nuestros programas son completamente GRATIS</h2>
                <p className="leading-relaxed text-white text-base text-justify">No te vendemos nada ni vendemos tus datos. Todos nuestros cursos y apoyo son 100% gratuitos en todo el mundo gracias a nuestro modelo de patrocinios.
                </p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default WhyTicongleIncubator