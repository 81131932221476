import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import data from '../data/logos.json';

function Alliance() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="body-fon py-24" id="ALIADOS">
      <div className="container px-5 pt-4 mx-auto">
        <div className="flex flex-col pb-8">
          <div className="text-center">
            <h1 className="text-gray-incubator md:text-4xl text-3xl text-center font-bold"> EN ALIANZA CON </h1>
          </div>
        </div>
        <div className="py-8">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            transitionDuration={1}
            customTransition="transform 2800ms"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
            rtl={true}
          >
            {data.slice(0, data.length / 2).map((value, index) => (
              <div key={index}>
                <img alt={value.alt} className="object-cover object-center block alliance-logos" src={require(`../assets/${value.logo}`)} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="py-8">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            transitionDuration={1}
            customTransition="transform 2800ms"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
          >
            {data.slice(data.length / 2, data.length).map((value, index) => (
              <div key={index}>
                <img alt={value.alt} className="object-cover object-center block alliance-logos" src={require(`../assets/${value.logo}`)} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Alliance;
