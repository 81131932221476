import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <>
      <footer className='bg-black' id="CONTACTANOS">
        <div className="bg-Dark-purple py-10 grid sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 md:px-16">
          <div className="flex md:p-0 w-full">
            <p className="text-sm text-white md:pl-4 md:py-2 md:mt-0 text-center block w-full">
              Copyright © 2024 <span className="font-bold  "> <br /> INCUBATOR</span> Hecho en El Salvador por
              <a href="https://www.conexion.sv/" target="_blank" className="text-yellow-incubaodr font-semibold text-sm"> CONEXION</a>
            </p>
          </div>
          <div className="">
            <ul className="text-center">
              <li className="text-white lg:text-sm text-xs font-semibold py-1 mt-3">
                <Link to="/Terminos-Y-Condiciones">TÉRMINOS Y CONDICIONES</Link>
              </li>

              <li className="text-white lg:text-sm text-xs font-semibold py-1">
                <Link to="/Politica-de-Privacidad">POLÍTICAS DE PRIVACIDAD</Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center md:py-0 py-3">
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start md:pr-14">
              <a className="text-gray-500" href='https://www.facebook.com/conexion.sv'>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>

              <a className="ml-3 text-gray-500" href='https://twitter.com/CONEXION_SV'>
              <FontAwesomeIcon icon={faXTwitter} />              </a>

              <a className="ml-3 text-gray-500" href='https://www.instagram.com/conexion.sv/'>
              <FontAwesomeIcon icon={faInstagram} />
              </a>

              <a className="ml-3 text-gray-500" href='https://www.linkedin.com/company/conexion_sv/'>
              <FontAwesomeIcon icon={faLinkedinIn} />
              </a>

            </span>
          </div>
        </div>

      </footer>

    </>
  )
}

export default Footer