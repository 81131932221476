import React from 'react'

import coheteAnimate from "../assets/cohete/completo.svg";

import SvgComponentCohete from './animate-cohete/cohete';
import SvgComponentHumo from './animate-cohete/complementCohete';

function Header() {

    return (
        <div className="container-fluid body-fon bg-blue-incubator-header sm:pt-12 xl:pt-0" id="INICIO">

            <div className="mx-auto hidden lg:block">
                {/* <div className="flex flex-wrap w-full relative h-[745px]"> */}
                <div className="w-full h-full relative responsive-py-header">

                    <div>
                        <SvgComponentCohete className="w-full object-cover  object-bottom block absolute inset-0 animatep z-10 bottom-0" />
                        <SvgComponentHumo className="w-full object-cover  object-bottom block absolute inset-0 animatep bottom-0" />
                    </div>

                    <div className="relative z-10 container-fluid">

                        <section className="text-gray-600 body-font">

                            <div className="header-content">

                                <div className="flex flex-wrap text-center w-full">

                                    <div className="p-4 md:w-1/2 sm:w-1/1 w-full">

                                        <div className="text-left pl-5">
                                            <div className=" md:w-3/4 w-1/1 h-full" >

                                                <h1 className="p-2 text-yellow-incubaodr md:text-3xl text-3xl font-bold" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                                    Es más fácil desarrollar tu idea si cuentas con un equipo de expertos detrás de ti.
                                                </h1>



                                                <p className="p-2 text-white text-lg text-justify" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                                    Ticongle Incubator puede ser el equipo de expertos en incubación que tu idea de negocio necesita. No te pongas límites y desarrolla lo que tu mente imagina.
                                                </p>

                                                <h1 className="p-2 text-yellow-incubaodr text-2xl font-bold mb-8" data-aos="fade-right" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="1500">
                                                    Somos la incubadora donde las grandes ideas empiezan.
                                                </h1>

                                                <a href="#CONTACTANOS"
                                                    className="text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-non text-2xl font-semibold hover:bg-[#6c3880] transition-colors" data-aos="fade-right" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="1500"
                                                >Cuéntanos tu idea
                                                </a>

                                            </div>


                                        </div>

                                    </div>

                                    <div className="p-4 md:w-1/2 sm:w-1/1 w-full">

                                        <div className="flex justify-end items-end pt-36">

                                            <div className="w-4/5 ">

                                                <h1 className=" text-yellow-incubaodr text-3xl font-bold" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                                    ¿Qué es TICONGLE INCUBATOR?
                                                </h1>

                                                <p className="p-2 text-white text-lg text-justify" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                                                    Ticongle Incubator es la primera y única incubadora de Startups en El Salvador que nace con la idea de apoyar en el desarrollo de los emprendedores salvadoreños para que, en un tiempo determinado, sean parte de las grandes empresas salvadoreñas.
                                                </p>

                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>

                        </section>

                    </div>
                </div>
            </div>

            <div className="block lg:hidden">
                <section className="text-gray-600 body-font bg-blue-incubator-header">

                    <div className=" pt-11 mx-auto">

                        <div className="flex flex-wrap">


                            <div className="p-4 lg:w-1/2 w-1/1 px-4">
                                <div className="text-left">
                                    <div className=" lg:w-3/4 w-4/4 w-1/1 h-full pt-14">

                                        <h1 className="p-2 text-yellow-incubaodr md:text-4xl text-xl font-bold text-center">
                                            Es más fácil desarrollar tu idea si cuentas con un equipo de expertos tras de ti</h1>

                                        <p className="p-2 text-white text-base text-justify">
                                            Ticongle incubator puede ser el equipo de expertos en incubación que tu idea
                                            de negocio necesita, no te pongas límites y desarrolla lo que tu mente
                                            imagina.
                                        </p>

                                        <h1 className="p-2 text-yellow-incubaodr text-xl font-bold text-center">Somos la incubadora
                                            donde las grandes ideas empiezan</h1>

                                        <div className="flex justify-center">
                                            <a href="#CONTACTANOS"
                                                className="mt-8 text-white bg-purpel-incubator border-0 
                                     rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 
                                     text-base font-semibold">
                                                Cuéntanos tu idea
                                            </a>
                                        </div>

                                    </div>


                                </div>
                            </div>

                            <div className="p-4 lg:w-1/2 w-1/1 px-4 ">
                                <div className="">

                                    {/* <h1 className=" text-yellow-incubaodr text-2xl  font-bold">¿Qué es TICONGLE
                                        INCUBATOR?
                                    </h1> */}
                                    <h1 className="p-2 text-yellow-incubaodr lg:text-4xl text-2xl lg:text-left text-center font-bold">
                                        ¿Qué es TICONGLE INCUBATOR?</h1>

                                    <p className="p-2 text-white text-base text-justify">
                                        Ticongle incubator, es la primera y única incubadora de StartUp en El
                                        Salvador,
                                        que nace con la idea de apoyar en el desarrollo de los emprendedores
                                        salvadoreños para que en un tiempo determinado sean parte de las grandes
                                        empresas salvadoreñas.
                                    </p>
                                </div>
                            </div>

                            <div className="w-full relative">
                                {/* <img class="w-full object-cover object-center block inset-0" src={coheteAnimate} /> */}
                                <SvgComponentCohete className="absolute z-10" />
                                <SvgComponentHumo className="absolute w-full bottom-[-1px]" />
                                <SvgComponentCohete className="invisible" />
                                {/* <app-animate-cohete class="w-full h-full object-center block inset-0 top-10"></app-animate-cohete> */}
                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </div>

    )
}

export default Header