import React from "react";
import ReactDOM from "react-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import App from "./app";

// const root = ReactDOM.createRoot(document.getElementById("root"));
const root = document.getElementById("root");
AOS.init();

ReactDOM.render(
  <React.StrictMode>
  <App></App>
  </React.StrictMode>,
  root
);
